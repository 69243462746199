// Here you can add other styles
.sidebar-nav a {
  text-decoration: none;
  color: #FFF !important;
}

.btn-primary {
  --cui-btn-bg: var(--cui-primary);
  --cui-btn-border-color: var(--cui-primary);
  --cui-btn-color: rgba(249, 250, 252, 0.95);
  --cui-btn-hover-bg: rgba(var(--cui-primary-rgb), 0.7);
  --cui-btn-hover-border-color: rgba(var(--cui-primary-rgb), 0.7);
  --cui-btn-hover-color: #000015;
  --cui-btn-active-bg: var(--cui-primary);
  --cui-btn-active-border-color: var(--cui-primary);
  --cui-btn-active-color: #fcfcfc;
  --cui-btn-disabled-bg: rgba(var(--cui-primary-rgb), 0.5);
  --cui-btn-disabled-border-color: rgba(var(--cui-primary-rgb), 0.5);
  --cui-btn-disabled-color: rgba(27, 27, 36, 0.95);
  --cui-btn-shadow: rgba(var(--cui-primary-rgb), 0.5);
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: var(--cui-primary);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.tablistNav .nav-item {
  border: 1px solid var(--cui-primary);
}

.tablistNav .nav-item>a.active {
  background-color: var(--cui-primary) !important;
  color: #fff !important;
}

.btn-outline-primary {
  --cui-btn-border-color: var(--cui-primary);
  --cui-btn-color: var(--cui-primary);
  --cui-btn-hover-bg: rgba(var(--cui-color2), 0.5);
  --cui-btn-hover-border-color: rgb(var(--cui-color2));
  --cui-btn-hover-color: #ffffff;
  --cui-btn-active-bg: rgb(var(--cui-color2));
  --cui-btn-active-border-color: rgb(var(--cui-color1));
  --cui-btn-active-color: #ffffff;
  --cui-btn-disabled-color: var(--cui-primary);
  --cui-btn-shadow: rgb(var(--cui-color1));
}

a {
  color: var(--cui-primary)
}

a:hover {
  color: rgba(var(--cui-primary-rgb), 1) !important;
}

.bg-primary {
  --cui-bg-opacity: 1;
  background-color: var(--cui-primary) !important;
}

.form-check-input:checked {
  background-color: var(--cui-primary);
  border-color: var(--cui-primary);
}

.form-control:focus,
.form-select:focus {
  color: rgba(44, 56, 74, 0.95);
  background-color: #fff;
  border-color: rgb(var(--cui-color1));
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(var(--cui-color1), 0.2);
}

.notification-container {
  box-sizing: border-box !important;
  position: fixed !important;
  bottom: 0 !important;
  right: 0 !important;
  top: auto !important;
  z-index: 999999;
  width: 320px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}

.bg-dark {
  --cui-bg-opacity: 1;
  background-color: #E0E0E0 !important;
}